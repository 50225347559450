











































import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
export default {
  name: 'uOttawa1321Prelab6Q4',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    const browserLanguage = navigator.language.split('-')[0]; // Extract the language code
    return {
      inputs: {
        studentAnswer: null,
        language: browserLanguage === 'fr' ? 'fr' : 'en', // Set the initial language based on browser preference
      },
      options: [
        {
          text: 'Molar ratio of each reactant with respect to the limiting reagent',
          value: 'molarRatioEachReactant',
        },
        {
          text: 'Mass ratio of each reactant with respect to the limiting reagent',
          value: 'massRatioEachReactant',
        },
        {
          text: 'Molar ratio of the work-up reagents with respect to the limiting reagent',
          value: 'molarRatioWorkupReagents',
        },
        {
          text: 'Mass ratio of the work-up reagents with respect to the limiting reagent',
          value: 'massRatioWorkupReagents',
        },
        {
          text: 'Molar ratio of each reagent with respect to the solvent',
          value: 'molarRatioSolvent',
        },
        {
          text: 'Mass ratio of each reagent with respect to the solvent',
          value: 'massRatioSolvent',
        },
      ],
      optionsFr: [
        {
          text: 'Ratio molaire de chacun des réactifs par rapport au réactif limitant',
          value: 'molarRatioEachReactant',
        },
        {
          text: 'Ratio massique de chacun des réactifs par rapport au réactif limitant',
          value: 'massRatioEachReactant',
        },
        {
          text: 'Ratio molaire de chacun des réactifs du parachèvement par rapport au réactif limitant',
          value: 'molarRatioWorkupReagents',
        },
        {
          text: 'Ratio massique de chacun des réactifs du parachèvement par rapport au réactif limitant',
          value: 'massRatioWorkupReagents',
        },
        {
          text: 'Ratio molaire de chacun des réactifs par rapport au solvant',
          value: 'molarRatioSolvent',
        },
        {
          text: 'Ratio massique de chacun des réactifs par rapport au solvant',
          value: 'massRatioSolvent',
        },
      ],
    };
  },
};
